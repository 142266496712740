import client from 'apollo-client'
import React, { useEffect, useState, useCallback } from 'react'
import { formatSeconds } from 'resources'
import { isEmpty, length, path, evolve, isNil, find, propEq } from 'ramda'

import { VIDEO_BY_ID_QUERY } from './graphql'
import placeholder from './placeholder.svg'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

const { error } = Modal

const useVideoInput = (value, tabs, disciplineTab) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [videoData, setVideoData] = useState({})
  const findVideoData = useCallback(videoId => {
    let videoGroup
    if (disciplineTab) {
      videoGroup = tabs.find((i) => i.id === disciplineTab)
    }
    setLoading(true)
    queryVideoById(videoId, videoGroup?.videoGroupId)
      .then((res) => {
        if (disciplineTab) {
          const video = normalizeData(videoGroup.videos, path(['data', 'createVideo'], res))
          setVideoData(video)
          return
        }

        tabs.forEach(e => {
          const video = normalizeData(e.videos, path(['data', 'createVideo'], res))
          setVideoData(video)
        })
      })
      .catch((e) => {
        setError(true)
        showInvalidItemsMessage()
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refetchVideo = e => {
    e.preventDefault()
  }

  useEffect(() => {
    if (shouldFetchVideo(value)) {
      findVideoData(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findVideoData, value])

  return {
    hasVideoData: !isNil(videoData),
    video: videoData,
    loading,
    refetchVideo,
    error,
    setError,
    placeholderData,
  }
}

const shouldFetchVideo = videoId => isEmpty(videoId) || length(videoId) > 28

const normalizeData = (tab, video) => {
  if (tab?.length > 0) {
    const result = find(propEq('id', video.id))(tab)
    if (result) {
      video.changedTitle = result.title
    }
  }
  return evolve({ timeInSeconds: formatSeconds })(video)
}

const queryVideoById = (videoId, videoGroupId) => client.mutate({
  mutation: VIDEO_BY_ID_QUERY,
  variables: { id: videoId, videoGroupId: videoGroupId },
  fetchPolicy: 'no-cache',
})

const placeholderData = {
  title: 'Título do vídeo',
  thumbnail: placeholder,
  timeInSeconds: 'Tempo de vídeo',
  isPlaceholder: true,
  error: true,
}

export const showInvalidItemsMessage = () =>
  error({
    title: 'Vídeo não localizado',
    content: 'O ID de vídeo informado não foi localizado na base, verifique se digitou corretamente. .',
    icon: <ExclamationCircleOutlined />,
    onOk: () => { },
    okText: 'Entendi',
  })
export { useVideoInput }
