import { gql } from '@apollo/client'

export const VIDEO_BY_ID_QUERY = gql`
  mutation VideoById($id: ID!, $videoGroupId: ID) {
    createVideo(id: $id, videoGroupId: $videoGroupId){
      id
      title
      timeInSeconds
      thumbnail
    }
  }
`

export const VIDEO_GROUP_QUERY = gql`
  query VideoById($id: ID!) {
    video(id: $id){
      id
      title
      timeInSeconds
      thumbnail
    }
  }
`
