import React, { useEffect } from 'react'
import t from 'prop-types'
import { Button, Input, Spin, Tag, Tooltip } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

import {
  LoadingWrapper,
  Wrapper,
  ThumbnailContainer,
  InputContainer,
  PreviewContainer,
  Thumbnail,
  Title,
  TitleWrapper,
  InfosWrapper,
} from './components'
import { useVideoInput } from './use-video-input'
import { Link } from 'react-router-dom'
import { CREATE_TIMELINE } from 'routes'

import { Form } from 'ui'

const VideoInput = ({
  showTimelineButton = false,
  editTitle = false,
  value,
  tabs = [],
  index,
  position,
  disciplineTab,
  ...props
}) => {
  const { video, loading, error, setError, placeholderData } = useVideoInput(
    value,
    tabs,
    disciplineTab
  )
  const { id, form } = { ...props }

  useEffect(() => {
    if (error) {
      if (form) {
        const fields = form.getFieldValue('disciplines')
        if (fields) {
          const updatedVideos = fields[0]?.videos.filter(
            (video, index) => video && index !== position
          )
          fields[0].videos = updatedVideos
          form.setFieldsValue({ disciplines: fields })
        }
      }
      setError(false)
    }
    if (editTitle) {
      form.setFieldsValue({
        videosTitle: {
          ...form.getFieldValue('videosTitle[0]'),
          [id]: video.changedTitle,
        },
      })
    }
  }, [editTitle, error, form, id, position, setError, video])

  return (
    <Wrapper>
      <InputContainer>
        <Input value={!error ? value : ''} id={id} {...props} />
        {showTimelineButton && (
          <Link
            to={CREATE_TIMELINE.replace(':id', video.id)}
            disabled={!(loading || video?.id)}
          >
            <Tooltip title='Editar timeline'>
              <Button
                size={props.size}
                disabled={!(loading || video?.id)}
                type='primary'
                icon={<SettingOutlined />}
              />
            </Tooltip>
          </Link>
        )}
      </InputContainer>

      <PreviewContainer>
        {loading && (
          <LoadingWrapper>
            <Spin />
          </LoadingWrapper>
        )}

        <ThumbnailContainer isPlaceholder={placeholderData.isPlaceholder}>
          {video?.thumbnail ? (
            <Thumbnail src={video.thumbnail} alt='Thumbnail' />
          ) : (
            <img src={placeholderData.thumbnail} alt='Thumbnail' />
          )}
        </ThumbnailContainer>

        <TitleWrapper>
          {editTitle && (
            <Form.Item
              name={['videosTitle', id]}
              initialValue={video.changedTitle}
              rules={rules.title}
            >
              <Input placeholder='Ex.: Titulo do Video' size='large' />
            </Form.Item>
          )}
          <InfosWrapper>
            <Title strong>{video.title}</Title>
            <Tag color='geekblue'>{video.timeInSeconds}</Tag>
          </InfosWrapper>
        </TitleWrapper>
      </PreviewContainer>
    </Wrapper>
  )
}

VideoInput.propTypes = {
  value: t.string,
  showTimelineButton: t.bool,
  size: t.string,
  editTitle: t.bool,
  tabs: t.array,
  index: t.number,
  position: t.number,
  disciplineTab: t.string,
}

const rules = {
  title: [
    {
      pattern: /^[\w .?!@¨´|()/{}~A-zÀ-ÿ \u2013-]{0,100}$/,
      message: 'Caracteres inválidos',
    },

    {
      max: 100,
      message: 'No máximo 100 caracteres.',
    },
  ],
}

export { VideoInput }
