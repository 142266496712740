import React from 'react'
import t from 'prop-types'
import { VideoInput, CardTabs } from 'ui'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { makeArray } from 'resources/composable'
import { dec, inc } from 'ramda'
import { Button, Col, Form, Row, Tag } from 'antd'
import { required } from 'resources/form-validation'
import { FileUploader } from 'ui/file-uploader/file-uploader'
const { TabPane } = CardTabs

const CommentedTestTabs = ({ removeTab, tabs, updateAmountOfVideos, isEditing, form }) => (
  <CardTabs
    style={{ display: tabs.length > 0 ? 'block' : 'none' }}
    forceRender
    onEdit={removeTab}
    defaultActiveKey='1'
    size='large'
    type='editable-card'
    hideAdd
  >
    {tabs.map((tab, index) => (
      <TabPane forceRender tab={tab.name} key={tab.id}>
        <Row gutter={[24, 12]}>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name={['disciplines', index, 'file']}
              rules={[required]}
              label='Arquivo'
            >
              <FileUploader isEditing={isEditing} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <Row gutter={[12, 12]} justify='end'>
              <Col xs={24} sm={6} md={6}>
                <Button
                  block
                  onClick={() => updateAmountOfVideos(tab.id, inc)}
                  type='primary'
                  icon={<PlusOutlined />}
                >
                  Adicionar vídeo
                </Button>
              </Col>
              <Col xs={24} sm={6} md={6}>
                <Button
                  disabled={tab.amountOfVideos === 1}
                  block
                  onClick={() => updateAmountOfVideos(tab.id, dec)}
                  type='danger'
                  icon={<MinusOutlined />}
                >
                  Remover vídeo
                </Button>
              </Col>
            </Row>

            {makeArray(tab.amountOfVideos).map((_, id) => (
              <Row key={id} gutter={[24, 12]} justify='space-between'>
                <Col xs={1}>
                  <Tag color='geekblue'>{id + 1}º</Tag>
                </Col>
                <Col xs={23}>
                  <Form.Item
                    label='ID do Vídeo'
                    name={['disciplines', index, 'videos', id]}
                    rules={[required]}
                  >
                    <VideoInput editTitle form={form} tabs={tabs} index={index} position={id} showTimelineButton disciplineTab={tab.id} />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </TabPane>
    ))}
  </CardTabs>
)

CommentedTestTabs.propTypes = {
  removeTab: t.func.isRequired,
  tabs: t.array.isRequired,
  updateAmountOfVideos: t.func.isRequired,
  isEditing: t.bool.isRequired,
  form: t.object,
}

export { CommentedTestTabs }
